import { render, staticRenderFns } from "./AlertType.vue?vue&type=template&id=4aa38b7b"
import script from "./AlertType.vue?vue&type=script&lang=js"
export * from "./AlertType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports